<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0" style="margin-bottom: 10px;">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-3 p-0" :active="true" href="#settings" :title="$t('builder.settings')"/>
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#seo" :title="$t('builder.seo')"/>
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#share" :title="$t('builder.share')"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
        <tab-content id="pills-tabContent-2">
          <tab-content-item id="settings" :active="true">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('builder.settings') }}</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-form-group class="col-md-12" :label="$t('builder.site_address')" label-for="siteaddress">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroupPrepend2">https://site.12booky.com/</span>
                      </div>
                      <input type="text" v-model="websiteSettings.username" class="form-control" id="validationDefaultUsername"  aria-describedby="inputGroupPrepend2" required>
                      <div class="input-group-prepend">
                        <b-button class="btn-primary btn" @click="openPage" id="openwebsitebutton">{{ $t('builder.open_page') }}</b-button>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('builder.template')" label-for="template">
                    <div class="row">
                      <div @click="websiteSettings.template = 1" class="col-sm-2 text-center p-2 border-warning" :class="{ border: websiteSettings.template === 1 }">
                        <img :src="require('../../assets/images/Avilon.png')" style="width:100%"/>
                        Avilon
                      </div>
                      <div @click="websiteSettings.template = 2" class="col-sm-2 text-center p-2 border-warning" :class="{ border: websiteSettings.template === 2 }">
                        <img :src="require('../../assets/images/Dewi.png')" style="width:100%"/>
                        Dewi
                      </div>
                      <div @click="websiteSettings.template = 3" class="col-sm-2 text-center p-2 border-warning" :class="{ border: websiteSettings.template === 3 }">
                        <img :src="require('../../assets/images/iPortfolio.png')" style="width:100%"/>
                        iPortofolio
                      </div>
                      <div @click="websiteSettings.template = 4" class="col-sm-2 text-center p-2 border-warning" :class="{ border: websiteSettings.template === 4 }">
                        <img :src="require('../../assets/images/Lonely.png')" style="width:100%"/>
                        Lonely
                      </div>
                      <div @click="websiteSettings.template = 5" class="col-sm-2 text-center p-2 border-warning" :class="{ border: websiteSettings.template === 5 }">
                        <img :src="require('../../assets/images/Medilab.png')" style="width:100%"/>
                        Medilab
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label=" $t('builder.home_background_picture') + ': ' + websiteSettings.homePicture">
                    <b-form-file :placeholder="$t('builder.home_background_picture')" v-model="websiteSettings.homePicture"></b-form-file>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('builder.home_video')">
                    <b-form-input type="text" :placeholder="$t('builder.home_video')" v-model="websiteSettings.homeVideo"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('builder.home_text')">
                    <b-form-textarea :placeholder="$t('builder.home_text')" v-model="websiteSettings.homeText"></b-form-textarea>
                  </b-form-group>

                  <b-form-group class="col-md-12" :label="$t('builder.activate_about_us')">
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                      <div class="custom-switch-inner">
                        <input type="checkbox" class="custom-control-input bg-primary" id="activate_aboutus" v-model="websiteSettings.aboutus">
                        <label class="custom-control-label" for="activate_aboutus" :data-on-label="$t('builder.yes')" :data-off-label="$t('builder.no')">
                        </label>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('builder.about_us_image') + ': ' + websiteSettings.aboutusImage">
                    <b-form-file :placeholder="$t('builder.about_us_image')" v-model="websiteSettings.aboutusImage"></b-form-file>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('builder.activate_about_text')">
                    <b-form-textarea :placeholder="$t('builder.activate_about_text')" v-model="websiteSettings.aboutusText"></b-form-textarea>
                  </b-form-group>

                  <b-form-group class="col-md-12" :label="$t('builder.show_services')">
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                      <div class="custom-switch-inner">
                        <input type="checkbox" class="custom-control-input bg-primary" id="show_services" v-model="websiteSettings.services">
                        <label class="custom-control-label" for="show_services" :data-on-label="$t('builder.yes')" :data-off-label="$t('builder.no')">
                        </label>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group class="col-md-12" :label="$t('builder.show_prices')">
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                      <div class="custom-switch-inner">
                        <input type="checkbox" class="custom-control-input bg-primary" id="show_prices" v-model="websiteSettings.prices">
                        <label class="custom-control-label" for="show_prices" :data-on-label="$t('builder.yes')" :data-off-label="$t('builder.no')">
                        </label>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group class="col-md-12" :label="$t('builder.show_employees')">
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                      <div class="custom-switch-inner">
                        <input type="checkbox" class="custom-control-input bg-primary" id="show_employees" v-model="websiteSettings.employees">
                        <label class="custom-control-label" for="show_employees" :data-on-label="$t('builder.yes')" :data-off-label="$t('builder.no')">
                        </label>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group class="col-md-12" :label="$t('builder.show_booking')">
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                      <div class="custom-switch-inner">
                        <input type="checkbox" class="custom-control-input bg-primary" id="show_booking" v-model="websiteSettings.booking">
                        <label class="custom-control-label" for="show_booking" :data-on-label="$t('builder.yes')" :data-off-label="$t('builder.no')">
                        </label>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group class="col-md-12" :label="$t('builder.show_contact')">
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                      <div class="custom-switch-inner">
                        <input type="checkbox" class="custom-control-input bg-primary" id="show_contact" v-model="websiteSettings.contact">
                        <label class="custom-control-label" for="show_contact" :data-on-label="$t('builder.yes')" :data-off-label="$t('builder.no')">
                        </label>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group class="col-md-12" :label="$t('builder.show_map')">
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                      <div class="custom-switch-inner">
                        <input type="checkbox" class="custom-control-input bg-primary" id="show_map" v-model="websiteSettings.map">
                        <label class="custom-control-label" for="show_map" :data-on-label="$t('builder.yes')" :data-off-label="$t('builder.no')">
                        </label>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('builder.facebook_posts')" label-for="fbposts">
                    <b-form-input type="text" v-model="websiteSettings.facebookPosts" id="fbposts" placeholder="Facebook posts"></b-form-input>
                  </b-form-group>

                  <b-form-group class="col-md-12" :label="$t('builder.map_link')" label-for="map">
                    <b-form-input type="text" v-model="websiteSettings.mapLink" id="map" placeholder="Map link"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-12 text-center">
                    <b-button class="btn-primary btn" @click="saveWebsite">{{ $t('builder.save') }}</b-button>
                  </b-form-group>

                </b-row>
              </template>
            </iq-card>
          </tab-content-item>
          <tab-content-item id="seo" :active="false">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('builder.seo') }}</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-form-group class="col-md-12" :label="$t('builder.favicon') + ': ' + websiteSeo.favicon" label-for="title">
                    <b-form-file :placeholder="$t('builder.favicon')" v-model="websiteSeo.favicon"></b-form-file>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('builder.page_title')" label-for="title">
                    <b-form-input type="text" id="title" :placeholder="$t('builder.page_title')" v-model="websiteSeo.title"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('builder.page_description')" label-for="description">
                    <b-form-textarea id="description" :placeholder="$t('builder.page_description')" v-model="websiteSeo.description"></b-form-textarea>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('builder.keywords')" label-for="keywords">
                    <b-form-input type="text" id="keywords" :placeholder="$t('builder.keywords')" v-model="websiteSeo.keywords"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-12 text-center">
                    <b-button class="btn-primary btn" @click="saveWebsite">{{ $t('builder.save') }}</b-button>
                  </b-form-group>
                </b-row>
              </template>
            </iq-card>
          </tab-content-item>
          <tab-content-item id="share" :active="false">
            <iq-card>
              <template v-slot:body>
                <div class="w-100">
                  <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + shareLink + websiteSettings.username" target="_blank">
                    <i class="ri-facebook-circle-fill" style="font-size: 50px"></i>
                  </a>
                  <a :href="'http://twitter.com/share?url=' + shareLink + websiteSettings.username" target="_blank">
                    <i class="ri-twitter-fill" style="font-size: 50px"></i>
                  </a>
                  <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + shareLink + websiteSettings.username" target="_blank">
                    <i class="ri-linkedin-box-fill" style="font-size: 50px"></i>
                  </a>
                  <span class="text-center">
                    <vue-q-r-code-component
                      :text="shareLink + websiteSettings.username"
                      size="200"
                      color="#000"
                      bg-color="#fff"
                      error-level="L">
                    </vue-q-r-code-component>
                  </span>
                  <br><br>
                  Embed Calendar Iframe: <br>
                  <b>{{ embedCalendar }}</b>
                </div>
              </template>
            </iq-card>
          </tab-content-item>
        </tab-content>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { core } from '@/config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
import VueQRCodeComponent from 'vue-qrcode-component'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'Builder',
  components: {
    VueQRCodeComponent
  },
  data () {
    return {
      device: Capacitor.getPlatform() || 'web',
      selectedTemplate: 'bg-warning',
      apiToken: localStorage.getItem('api_token') || '',
      shareLink: 'https://site.12booky.com/',
      websiteSettings: {
        username: '',
        template: 1,
        homePicture: '',
        homeVideo: '',
        homeText: '',
        aboutus: false,
        aboutusImage: '',
        aboutusText: '',
        services: false,
        prices: false,
        employees: false,
        booking: false,
        contact: false,
        map: false,
        facebookPosts: '',
        mapLink: ''
      },
      websiteSeo: {
        favicon: '',
        title: '',
        description: '',
        keywords: ''
      },
      embedCalendar: ''
    }
  },
  mounted () {
    core.index()
    this.getWebsite()
  },
  methods: {
    openPage () {
      const dataPost = {
        url: this.websiteSettings.username
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_WEBSITE_URL, dataPost)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', this.$t('builder.add_different_address'))
          } else if (response.data.status === 'success') {
            if (this.device === 'ios') {
              const openCapacitorSite = async () => {
                await Browser.open({ url: this.shareLink + this.websiteSettings.username })
              }
              console.log(openCapacitorSite())
            } else {
              window.open(this.shareLink + this.websiteSettings.username, '_blank')
            }
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getWebsite () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_WEBSITE)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', this.$t('builder.add_different_address'))
          } else if (response.data.status === 'success') {
            this.embedCalendar = '<iframe src="https://app.12booky.com/' + response.data.token + '/booking" width="100%"></iframe>'
            this.websiteSettings.username = response.data.website.username
            this.websiteSeo.favicon = response.data.website.favicon
            this.websiteSeo.title = response.data.website.title
            this.websiteSeo.description = response.data.website.description
            this.websiteSeo.keywords = response.data.website.keywords
            this.websiteSettings.template = parseInt(response.data.website.template)
            const theContent = JSON.parse(response.data.website.content)
            this.websiteSettings.homeText = theContent.homeText
            this.websiteSettings.homeVideo = theContent.homeVideo
            this.websiteSettings.homePicture = theContent.homePicture
            this.websiteSettings.aboutus = (theContent.aboutUs === 'true')
            this.websiteSettings.aboutusText = theContent.aboutUsText
            this.websiteSettings.aboutusImage = theContent.aboutUsPicture
            this.websiteSettings.services = (theContent.services === 'true')
            this.websiteSettings.prices = (theContent.prices === 'true')
            this.websiteSettings.employees = (theContent.employees === 'true')
            this.websiteSettings.booking = (theContent.booking === 'true')
            this.websiteSettings.contact = (theContent.contact === 'true')
            this.websiteSettings.map = (theContent.map === 'true')
            this.websiteSettings.mapLink = theContent.mapLink
            this.websiteSettings.facebookPosts = theContent.facebookPosts
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveWebsite () {
      const formData = new FormData()
      formData.append('username', this.websiteSettings.username)
      formData.append('template', this.websiteSettings.template)
      formData.append('homePicture', this.websiteSettings.homePicture)
      formData.append('homeVideo', this.websiteSettings.homeVideo)
      formData.append('homeText', this.websiteSettings.homeText)
      formData.append('aboutUs', this.websiteSettings.aboutus)
      formData.append('aboutUsPicture', this.websiteSettings.aboutusImage)
      formData.append('aboutUsText', this.websiteSettings.aboutusText)
      formData.append('services', this.websiteSettings.services)
      formData.append('prices', this.websiteSettings.prices)
      formData.append('employees', this.websiteSettings.employees)
      formData.append('booking', this.websiteSettings.booking)
      formData.append('contact', this.websiteSettings.contact)
      formData.append('map', this.websiteSettings.map)
      formData.append('facebookPosts', this.websiteSettings.facebookPosts)
      formData.append('mapLink', this.websiteSettings.mapLink)
      formData.append('favicon', this.websiteSeo.favicon)
      formData.append('title', this.websiteSeo.title)
      formData.append('description', this.websiteSeo.description)
      formData.append('keywords', this.websiteSeo.keywords)

      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SAVE_WEBSITE, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('builder.website_updated'))
          } else if (response.data.status === 'upgrade') {
            this.$emit('upgrade_message', response.data.nextPlan)
            this.$bvModal.show('globalUpgrade')
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>
<style>
.border-warning{
  cursor:pointer;
}
.border-warning:hover{
  background: var(--iq-success);
  color: #fff;
}
.border.border-warning{
  background: var(--iq-primary);
  color: #fff;
}
@media only screen and (max-width: 556px){
  #inputGroupPrepend2,
  #validationDefaultUsername,
  #openwebsitebutton
  {
    font-size: 10px !important;
  }
  #openwebsitebutton{
    padding: 5px;
  }
}
</style>
